import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import BaseSwiper from '../../../../@rtg2022/components/organisms/BaseSwiper/BaseSwiper'
import StrapiHeader from '../Header'
import { ImageGalleryWrapper } from './ImageGallery.styles'
import {
  getTiles,
  generateDesktopSlides,
  generateMobileSlides,
  mobileVariants,
  configObj,
  desktopVariants,
  SpaceBetweenEnum,
} from './helpers'

const StrapiImageGallery = ({ data, headingStyles, imageGalleryProps }) => {
  const ImageGallery = data?.ImageGallery || data
  const {
    id,
    Images,
    DisplayScrollbar,
    DisplayDesktop,
    DisplayMobile,
    testId,
    Spacing,
    MobileSpacing,
    Header,
    DesktopLayout,
    MobileLayout,
    Tiles,
    GapBetweenTiles,
    GapBetweenTilesMobile,
    GlobalLink,
    Title,
  } = ImageGallery
  const items = Tiles ?? Images
  const type = Tiles ? 'dynamic' : 'images'
  const mobileVariant = MobileLayout ?? mobileVariants.default
  const desktopVariant = DesktopLayout ?? desktopVariants.default
  const spaceBetweenDesktop = desktopVariant === 'variant1' ? 12 : SpaceBetweenEnum[GapBetweenTiles ?? 'large']
  const spaceBetweenMobile = SpaceBetweenEnum[GapBetweenTilesMobile ?? 'medium']

  const tiles = useMemo(() => getTiles(items, type, id, GlobalLink, Title), [items, type, id, GlobalLink, Title])
  const desktopSlides = useMemo(() => generateDesktopSlides(tiles, DesktopLayout, id, spaceBetweenDesktop), [
    tiles,
    DesktopLayout,
    id,
    spaceBetweenDesktop,
  ])
  const mobileSlides = useMemo(() => generateMobileSlides(tiles, MobileLayout, id, spaceBetweenMobile), [
    tiles,
    MobileLayout,
    id,
    spaceBetweenMobile,
  ])

  if (!desktopSlides && !mobileSlides) return null

  return (
    <ImageGalleryWrapper
      Spacing={!imageGalleryProps?.disableSpacing && Spacing}
      MobileSpacing={!imageGalleryProps?.disableSpacing && MobileSpacing}
      DisplayDesktop={DisplayDesktop}
      DisplayMobile={DisplayMobile}
    >
      {Header && !imageGalleryProps?.disableHeader && (
        <StrapiHeader
          data={Header}
          headingStyles={headingStyles ?? { marginBottom: '25px', fontSize: '25px', fontSizeMobile: '20px' }}
        />
      )}
      <BaseSwiper
        desktopSlides={desktopSlides}
        mobileSlides={mobileSlides}
        slidesPerViewDesktop={configObj.slidesPerViewDesktop[desktopVariant]}
        slidesPerViewMobile={configObj.slidesPerViewMobile[mobileVariant]}
        spaceBetween={spaceBetweenDesktop}
        spaceBetweenMobile={spaceBetweenMobile}
        hideScrollbar={DisplayScrollbar === false || imageGalleryProps?.disableScrollbar}
        fadeScrollbar={configObj.fadeScrollbar[mobileVariant]}
        dataTestId={testId}
        fullWidthMobile={configObj.fullWidthMobile[mobileVariant]}
        freeModeDesktop={configObj.freeModeDesktop[desktopVariant]}
        freeModeMobile={configObj.freeModeMobile[mobileVariant]}
        navigationVariant={configObj.navigationVariantDesktop[desktopVariant]}
      />
    </ImageGalleryWrapper>
  )
}

StrapiImageGallery.propTypes = {
  data: PropTypes.object.isRequired,
  headingStyles: PropTypes.object,
  imageGalleryProps: {
    disableSpacing: PropTypes.bool,
    disableHeader: PropTypes.bool,
    disableScrollbar: PropTypes.bool,
  },
}

export const StrapiImageGalleryFragment = graphql`
  fragment ImageGalleryFragment on StrapiImageGallery {
    id
    testId
    Title
    DisplayDesktop
    DisplayMobile
    DisplayScrollbar
    GapBetweenTiles
    GapBetweenTilesMobile
    Spacing {
      BottomPadding
      TopPadding
    }
    MobileSpacing {
      BottomPadding
      TopPadding
    }
    Images {
      alternativeText
      url
    }
    Header {
      ...StrapiHeaderFragment
    }
    DesktopLayout
    MobileLayout
    Tiles {
      __typename
      ... on BaseCategoryTile {
        CategoryTile {
          ...StrapiCategoryTileFragment
        }
      }
      ... on BaseImage {
        Image {
          ...StrapiImageFragment
        }
      }
      ... on PageVideo {
        Video {
          ...StrapiPageVideoFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on BaseImageWithSku {
        ...BaseImageWithSkuFragment
      }
    }
    GlobalLink {
      Link {
        ...StrapiLinkFragment
      }
    }
  }
`

export default StrapiImageGallery
