import { styled } from '@mui/material/styles'

export const ImageGalleryWrapper = styled('div')(
  ({ theme, Spacing, MobileSpacing, DisplayDesktop, DisplayMobile }) => ({
    display: DisplayDesktop === false ? 'none' : 'block',
    padding: DisplayDesktop === false ? 0 : `${Spacing?.TopPadding ?? 0}px 0 ${Spacing?.BottomPadding ?? 0}px`,
    [theme.breakpoints.down('md')]: {
      display: DisplayMobile === false ? 'none' : 'block',
      padding:
        DisplayMobile === false ? 0 : `${MobileSpacing?.TopPadding ?? 0}px 0 ${MobileSpacing?.BottomPadding ?? 0}px`,
    },
  }),
)

export const DesktopSlideGrid = styled('div')(({ GapBetweenTiles }) => ({
  display: 'grid',
  gap: `${GapBetweenTiles ?? 10}px`,
  gridTemplateColumns: '2fr 1fr',
  gridTemplateRows: '320px 320px',
}))

export const DesktopVariant3SlideGrid = styled('div')(({ GapBetweenTiles }) => ({
  display: 'grid',
  gap: `${GapBetweenTiles ?? 10}px`,
  gridTemplateColumns: '1fr 2fr',
  gridTemplateRows: '320px 320px',
}))

export const MobileImgWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: '450px',
  },
  '@media only screen and (max-width: 800px)': {
    height: '330px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '280px',
  },
  '@media only screen and (max-width: 450px)': {
    height: '220px',
  },
}))

export const MobileImgWrapperThreeSlidesVariant = styled('div')(({ theme, GapBetweenTiles }) => ({
  display: 'grid',
  gap: `${GapBetweenTiles ?? 10}px`,
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '7fr 4fr',

  [theme.breakpoints.down('md')]: {
    height: '660px',
  },
  '@media only screen and (max-width: 800px)': {
    height: '590px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '520px',
  },
  '@media only screen and (max-width: 450px)': {
    height: '450px',
  },
}))

export const DynamicSlide = styled('div')(({ theme, GapBetweenTiles }) => ({
  display: 'grid',
  gap: `${GapBetweenTiles ?? 10}px`,
  gridTemplateColumns: 'repeat(12, 1fr)',
  gridTemplateRows: 'repeat(12, 1fr)',

  [theme.breakpoints.down('md')]: {
    height: '660px',
  },
  '@media only screen and (max-width: 800px)': {
    height: '590px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '520px',
  },
  '@media only screen and (max-width: 450px)': {
    height: '450px',
  },
}))

export const DynamicSlideDesktopOriginal = styled('div')(({ theme, GapBetweenTiles, aspectRatio = '2 / 1' }) => ({
  display: 'grid',
  gap: `${GapBetweenTiles ?? 10}px`,
  gridTemplateColumns: 'repeat(24, 1fr)',
  gridTemplateRows: 'repeat(24, 1fr)',
  aspectRatio,
}))

export const DynamicSlideDesktop = styled('div')(
  ({ theme, GapBetweenTiles, cols = '1fr 1fr', rows = '1fr 1fr', width, maxWidth }) => ({
    display: 'grid',
    gridGap: `${GapBetweenTiles ?? 10}px`,
    gridTemplateColumns: cols,
    gridTemplateRows: rows,
    height: '49.2vw',
    maxHeight: '712px',
    width,
    maxWidth,
  }),
)

export const DynamicSlideMobile = styled('div')(
  ({ theme, GapBetweenTiles, cols = '1fr 1fr', rows = '1fr 1fr', width, maxWidth }) => ({
    display: 'grid',
    gridGap: `${GapBetweenTiles ?? 10}px`,
    gridTemplateColumns: cols,
    gridTemplateRows: rows,
    height: '106.4vw',
    maxHeight: '500px',
    width,
    maxWidth,
  }),
)
