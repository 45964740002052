// dependencies
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Card, CardActionArea, CardMedia, CardContent, Typography, makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import { useMediaQuery, useTheme } from '@mui/material'
// hooks
import useHoverElevation from '@hooks/useHoverElevation'
// components
import SaleFlag from '../../../@rtg2022/components/atoms/SaleFlag/SaleFlag'
import { getLinkProps } from './Link'
import StrapiImage from './Image'
import ImageWithSku from './ImageWithSku'

const useStyles = makeStyles(theme => ({
  card: {
    cursor: 'pointer',
    borderRadius: '0px',
    transition: ({ isSsr }) => {
      if (isSsr) {
        return 'none'
      }
      return theme.transitions.create(['all'], {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.short,
      })
    },
    '&:hover': {
      transform: ' scale(1.01)',
    },
    '& *:focus': {
      outline: 'none !important',
    },
    '& span[role=button] div[data-testid=saleFlag]': {
      top: 0,
      left: 0,
    },
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  media: ({ isCollapsed, isCollapsedImageTop }) => {
    if (isCollapsed) {
      return {
        width: '100% !important',
        height: '5.6rem !important',
        objectFit: 'contain !important',
        display: 'flex !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
      }
    }
    if (isCollapsedImageTop) {
      return { width: '100% !important', height: '5rem !important', objectFit: 'contain !important' }
    }
    return {
      height: 'auto',
      maxHeight: 650,
      margin: '0 auto',
      width: '100%',
      objectFit: 'cover',
    }
  },
  cardName: ({ isCollapsed }) => {
    let cardNameOverflow = {}
    if (isCollapsed) {
      cardNameOverflow = {
        maxHeight: '46px',
        overflow: 'hidden',
      }
    }
    return {
      ...cardNameOverflow,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '1rem',
      fontWeight: 600,
      padding: isCollapsed ? '0 0 0 10px' : theme.spacing(1),
      lineHeight: 1,
    }
  },
  collapsed: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.875rem',
    width: 'auto',
  },
  collapsedTop: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px',
  },
  imageWrapper: ({ isCollapsed, isCollapsedImageTop }) => {
    if (isCollapsed) {
      return { width: '50% !important' }
    }
    if (isCollapsedImageTop) {
      return { width: '45%' }
    }
    return {}
  },
  content: ({ isCollapsed }) => {
    if (isCollapsed) {
      return { width: '50%', padding: 0 }
    }
    return { width: '100%', padding: 0 }
  },
  flagWrapper: {
    left: '10px',
    top: '10px',
    padding: '5px 10px',
    '& .MuiTypography-root': {
      fontSize: '18px',
      lineHeight: '28px',
    },
    [theme.breakpoints.down('md')]: {
      left: '5px',
      top: '5px',
      borderRadius: '4px',
      padding: '4px 8px',
      '& .MuiTypography-root': {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  },
}))

export const splitName = (name, characterBreak, isMobile) => {
  if (!characterBreak || !isMobile) return [name]
  const nameLine1 = name.slice(0, characterBreak)
  const nameLine2 = name.slice(characterBreak)
  return [nameLine1, nameLine2]
}

const StrapiCategoryTile = ({ data }) => {
  const [isSsr, setIsSsr] = useState(true)
  const { onMouseEnter, onMouseLeave, elevation } = useHoverElevation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const link = data?.ImageWithSku?.ImageWithSku?.Link?.Link || data?.Link?.Link
  const buttonProps = link
    ? {
        ...getLinkProps(data, link, { category: 'category tile' }),
        tabIndex: null,
        'aria-disabled': null,
        role: 'none',
      }
    : {}
  const isCollapsed = Boolean(data?.Collapsed)
  const isCollapsedImageTop = Boolean(data?.CollapsedImageTop)
  const classes = useStyles({ isCollapsed, isCollapsedImageTop, isSsr })
  const mediaProps = isCollapsed
    ? { height: 85, role: 'presentation', 'aria-hidden': 'true' }
    : { role: 'presentation', 'aria-hidden': 'true' }
  const imageWithSku = data?.ImageWithSku
  const titleArray = splitName(data?.Name, data?.NameBreakCharacter, isMobile)

  useEffect(() => {
    setIsSsr(false)
  }, [])

  return (
    <Card
      data-testid={data?.testId}
      className={classes.card}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      elevation={elevation}
    >
      <CardActionArea
        className={classNames(classes.link, {
          [classes.collapsed]: isCollapsed,
          [classes.collapsedTop]: isCollapsedImageTop,
        })}
        {...buttonProps}
      >
        {data?.OnSale && <SaleFlag label="SALE" className={classes.flagWrapper} />}
        {imageWithSku && (
          <CardMedia
            component={StrapiImage}
            className={classes.media}
            wrapperClassName={classes.imageWrapper}
            data={{
              desktop: data?.ImageWithSku.ImageWithSku.Image,
              alt: data?.Name,
            }}
            title={data?.Title}
            {...mediaProps}
          />
        )}
        {data?.Image?.url && !imageWithSku && (
          <CardMedia
            component={StrapiImage}
            className={classes.media}
            wrapperClassName={classes.imageWrapper}
            data={{
              desktop: data?.Image,
              alt: '',
            }}
            title={data?.Title}
            {...mediaProps}
          />
        )}
        {data?.Name && (
          <CardContent className={classes.content}>
            <Typography className={classes.cardName} color="textPrimary" variant="body1" component="h4">
              {titleArray[0]}
            </Typography>
            {titleArray?.length > 1 && (
              <Typography
                className={classes.cardName}
                style={{ marginTop: '0' }}
                color="textPrimary"
                variant="body1"
                component="h4"
              >
                {titleArray[1]}
              </Typography>
            )}
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  )
}

StrapiCategoryTile.propTypes = {
  data: PropTypes.object.isRequired,
}

export const StrapiCategoryTileFragment = graphql`
  fragment StrapiCategoryTileFragment on StrapiCategoryTile {
    id
    testId
    __typename
    Collapsed
    CollapsedImageTop
    Title
    Name
    OnSale
    MobileNameBreak
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
    ImageWithSku {
      ...BaseImageWithSkuFragment
    }
    Image {
      ...StrapiImageFragment
    }
    ImageIncludesBackground
    DisplayBottomLink
    BottomLink {
      Link {
        ...StrapiLinkFragment
      }
    }
  }
`

export default StrapiCategoryTile
