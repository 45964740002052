import React from 'react'
import { Box } from '@mui/material'
import StrapiImage from '../Image'
import {
  DesktopSlideGrid,
  DesktopVariant3SlideGrid,
  MobileImgWrapper,
  MobileImgWrapperThreeSlidesVariant,
  DynamicSlide,
  DynamicSlideDesktopOriginal,
  DynamicSlideDesktop,
  DynamicSlideMobile,
} from './ImageGallery.styles'
import StrapiCategoryTileV2 from '../CategoryTileV2'
import StrapiMarkdown from '../Markdown'
import StrapiVideo from '../Video'
import StrapiLink from '../Link'
import ImageWithSku from '../ImageWithSku'

export const desktopVariants = {
  default: 'default: 1 left - 2 right',
  variant1: 'variant1',
  variant2: 'variant2',
  variant3: 'variant3: 2 left - 1 right',
}

export const mobileVariants = {
  default: 'default: 1 tile',
  variant1: 'variant1: 1 top - 2 bottom',
  variant2: 'variant2',
  variant3: 'variant3',
}

export const configObj = {
  fadeScrollbar: {
    [mobileVariants.default]: true,
    [mobileVariants.variant1]: false,
    [mobileVariants.variant2]: true,
    [mobileVariants.variant3]: true,
  },
  slidesPerViewDesktop: {
    [desktopVariants.default]: 1,
    [desktopVariants.variant1]: 'auto',
    [desktopVariants.variant2]: 1,
    [desktopVariants.variant3]: 1,
  },
  slidesPerViewMobile: {
    [mobileVariants.default]: 1.1,
    [mobileVariants.variant1]: 1,
    [mobileVariants.variant2]: 'auto',
    [mobileVariants.variant3]: 'auto',
  },
  fullWidthMobile: {
    [mobileVariants.default]: false,
    [mobileVariants.variant1]: false,
    [mobileVariants.variant2]: true,
    [mobileVariants.variant3]: true,
  },
  freeModeDesktop: {
    [desktopVariants.default]: false,
    [desktopVariants.variant1]: true,
    [desktopVariants.variant2]: true,
    [desktopVariants.variant3]: false,
  },
  freeModeMobile: {
    [mobileVariants.default]: false,
    [mobileVariants.variant1]: false,
    [mobileVariants.variant2]: true,
    [mobileVariants.variant3]: true,
  },
  navigationVariantDesktop: {
    [desktopVariants.default]: 'default',
    [desktopVariants.variant1]: 'variant1',
    [desktopVariants.variant2]: 'variant1',
    [desktopVariants.variant3]: 'default',
  },
}

/**
 * Generates array of tile components
 * @param {array} items
 * @param {string} type
 * @param {number} imageGalleryId
 * @returns {array}
 */
export const getTiles = (items, type, imageGalleryId, GlobalLink, Title) =>
  items
    ?.map?.(item => {
      const getKey = generateItemKey(item, imageGalleryId)

      if (type === 'images') {
        return <StrapiImage key={getKey('Image')} data={generateImgData(item)} noPaddingBottom />
      }

      const View = {
        BaseImage: item?.Image && (
          <StrapiImage key={getKey('Image')} data={generateImgData(item?.Image)} noPaddingBottom />
        ),
        BaseCategoryTile: item?.CategoryTile && (
          <StrapiCategoryTileV2
            key={getKey('CategoryTile')}
            data={item?.CategoryTile}
            fitContainer
            titleStyles={{ fontSizeXl: '16px', fontSizeLg: '16px', fontSizeMd: '14px' }}
          />
        ),
        PageVideo: item?.Video && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgb(8, 6, 6)',
              height: '100%',
              width: '100%',
              overflow: 'hidden',
              '& .video-box': {
                width: '100%',
              },
            }}
          >
            <StrapiVideo
              key={getKey('Video')}
              data={item}
              videoProps={{ height: 'auto', width: '100%', muted: true, autoplay: true, loop: true, controls: false }}
            />
          </Box>
        ),
        PageMarkdown: item?.Markdown && (
          <Box
            sx={{
              overflow: 'hidden',
              height: '100%',
              width: '100%',
            }}
          >
            <StrapiMarkdown key={getKey('Markdown')} data={item?.Markdown} />
          </Box>
        ),
        BaseImageWithSku: item?.ImageWithSku && (
          <Box
            sx={{
              overflow: 'hidden',
              height: '100%',
              width: '100%',
            }}
          >
            <ImageWithSku key={getKey('ImageWithSku')} data={item} />
          </Box>
        ),
      }

      if (!View[item?.__typename]) return null

      if (!GlobalLink) return View[item.__typename]

      return (
        <StrapiLink
          key={getKey('GlobalLink')}
          data={GlobalLink?.Link}
          trackingData={{
            category: 'navigation',
            action: 'link click',
            label: Title,
          }}
          style={{ textDecoration: 'none', cursor: 'pointer !important' }}
        >
          {View[item.__typename]}
        </StrapiLink>
      )
    })
    ?.filter?.(component => Boolean(component))

/**
 * Formats tiles into desktop slides
 * @param {array} tiles
 * @param {string} DesktopLayout
 * @param {string} imageGalleryId
 * @returns {array}
 */
export const generateDesktopSlides = (tiles = [], DesktopLayout = 'default', imageGalleryId, GapBetweenTiles) => {
  if (DesktopLayout === desktopVariants.variant1)
    return generateDesktopSlidesVariant1(tiles, imageGalleryId, GapBetweenTiles)
  if (DesktopLayout === desktopVariants.variant2)
    return generateDesktopSlidesVariant2(tiles, imageGalleryId, GapBetweenTiles)
  if (DesktopLayout === desktopVariants.variant3)
    return generateDesktopSlidesVariant3(tiles, imageGalleryId, GapBetweenTiles)
  return generateDesktopSlidesDefault(tiles, imageGalleryId, GapBetweenTiles)
}

// Latest design change for Desktop D&J
const generateDesktopSlidesVariant1 = (tiles = [], imageGalleryId, GapBetweenTiles) =>
  variedChunkedArray(tiles, [1, 3, 1, 3, 2]).map(([tile1, tile2, tile3], idx) => {
    const key = getSlideKey(imageGalleryId, tile1, 'desktop')
    if (idx % 5 === 0) {
      return (
        <DynamicSlideDesktop
          GapBetweenTiles={GapBetweenTiles}
          cols="1fr"
          rows="1fr"
          key={key}
          maxWidth="950.96px"
          width="66.04vw"
        >
          <Box sx={{ gridColumn: '1 / 2', gridRow: '1 / 2' }}>{tile1}</Box>
        </DynamicSlideDesktop>
      )
    }
    if (idx % 5 === 1) {
      return (
        <DynamicSlideDesktop
          key={key}
          GapBetweenTiles={GapBetweenTiles}
          cols={`repeat(2, calc(50% - ${GapBetweenTiles / 2}px))`}
          rows={`calc(49% - ${GapBetweenTiles / 2}px) calc(51% - ${GapBetweenTiles / 2}px)`}
          maxWidth="548.68px"
          width="38.1vw"
        >
          <Box sx={{ gridColumn: '1 / 3', gridRow: '1 / 2' }}>{tile1}</Box>
          <Box sx={{ gridColumn: '1 / 2', gridRow: '2 / 3' }}>{tile2}</Box>
          <Box sx={{ gridColumn: '2 / 3', gridRow: '2 / 3' }}>{tile3}</Box>
        </DynamicSlideDesktop>
      )
    }
    if (idx % 5 === 2) {
      return (
        <DynamicSlideDesktop
          key={key}
          GapBetweenTiles={GapBetweenTiles}
          cols="1fr"
          rows="1fr"
          maxWidth="890.96px"
          width="61.87vw"
        >
          <Box sx={{ gridColumn: '1 / 2', gridRow: '1 / 2' }}>{tile1}</Box>
        </DynamicSlideDesktop>
      )
    }
    if (idx % 5 === 3) {
      return (
        <DynamicSlideDesktop
          key={key}
          GapBetweenTiles={GapBetweenTiles}
          cols={`repeat(2, calc(50% - ${GapBetweenTiles / 2}px))`}
          rows={`calc(44% - ${GapBetweenTiles / 2}px) calc(56% - ${GapBetweenTiles / 2}px)`}
          maxWidth="627.8px"
          width="43.6vw"
        >
          <Box sx={{ gridColumn: '1 / 2', gridRow: '1 / 2' }}>{tile1}</Box>
          <Box sx={{ gridColumn: '2 / 3', gridRow: '1 / 2' }}>{tile2}</Box>
          <Box sx={{ gridColumn: '1 / 3', gridRow: '2 / 3' }}>{tile3}</Box>
        </DynamicSlideDesktop>
      )
    }
    return (
      <DynamicSlideDesktop
        key={key}
        GapBetweenTiles={GapBetweenTiles}
        cols="100%"
        rows={`calc(56.9% - ${GapBetweenTiles / 2}px) calc(43.1% - ${GapBetweenTiles / 2}px)`}
        maxWidth="299.28px"
        width="20.78vw"
      >
        <Box sx={{ gridColumn: '1 / 2', gridRow: '1 / 2' }}>{tile1}</Box>
        <Box sx={{ gridColumn: '1 / 2', gridRow: '2 / 3' }}>{tile2}</Box>
      </DynamicSlideDesktop>
    )
  })

const generateDesktopSlidesVariant2 = (tiles = [], imageGalleryId, GapBetweenTiles) =>
  chunkedArray(tiles, 8).map(([tile1, tile2, tile3, tile4, tile5, tile6, tile7, tile8], idx) => (
    <Box key={getSlideKey(imageGalleryId, tile1, 'desktop')}>
      <DynamicSlideDesktopOriginal GapBetweenTiles={GapBetweenTiles} aspectRatio="8 / 3">
        <Box sx={{ gridColumn: '1 / 7', gridRow: '1 / 13' }}>{tile1}</Box>
        <Box sx={{ gridColumn: '7 / 13', gridRow: '1 / 13' }}>{tile2}</Box>
        <Box sx={{ gridColumn: '13 / 19', gridRow: '1 / 13' }}>{tile3}</Box>
        <Box sx={{ gridColumn: '19 / 25', gridRow: '1 / 13' }}>{tile4}</Box>
        <Box sx={{ gridColumn: '1 / 7', gridRow: '13 / 25' }}>{tile5}</Box>
        <Box sx={{ gridColumn: '7 / 13', gridRow: '13 / 25' }}>{tile6}</Box>
        <Box sx={{ gridColumn: '13 / 19', gridRow: '13 / 25' }}>{tile7}</Box>
        <Box sx={{ gridColumn: '19 / 25', gridRow: '13 / 25' }}>{tile8}</Box>
      </DynamicSlideDesktopOriginal>
    </Box>
  ))

// variant3: 2 left - 1 right
const generateDesktopSlidesVariant3 = (tiles, imageGalleryId, GapBetweenTiles) =>
  chunkedArray(tiles, 3).map(([tile1, tile2, tile3], idx) => (
    <DesktopVariant3SlideGrid key={getSlideKey(imageGalleryId, tile1, 'desktop')} GapBetweenTiles={GapBetweenTiles}>
      <Box sx={{ gridColumn: '1 / 2', gridRow: '1 / 2' }}>{tile1}</Box>
      <Box sx={{ gridColumn: '1 / 2', gridRow: '2 / 3' }}>{tile2}</Box>
      <Box sx={{ gridColumn: '2 / 3', gridRow: '1 / 3' }}>{tile3}</Box>
    </DesktopVariant3SlideGrid>
  ))

// default: 1 left - 2 right
const generateDesktopSlidesDefault = (tiles, imageGalleryId, GapBetweenTiles) =>
  chunkedArray(tiles, 3).map(([tile1, tile2, tile3], idx) => (
    <DesktopSlideGrid key={getSlideKey(imageGalleryId, tile1, 'desktop')} GapBetweenTiles={GapBetweenTiles}>
      <Box sx={{ gridColumn: '1 / 2', gridRow: '1 / 3' }}>{tile1}</Box>
      <Box sx={{ gridColumn: '2 / 3', gridRow: '1 / 2' }}>{tile2}</Box>
      <Box sx={{ gridColumn: '2 / 3', gridRow: '2 / 3' }}>{tile3}</Box>
    </DesktopSlideGrid>
  ))

/**
 * Formats tiles into mobile slides
 * @param {array} tiles
 * @param {string} MobileLayout
 * @param {string} imageGalleryId
 * @returns {array}
 */
export const generateMobileSlides = (
  tiles = [],
  MobileLayout = mobileVariants.default,
  imageGalleryId,
  GapBetweenTiles,
) => {
  if (MobileLayout === mobileVariants.variant1)
    return generateMobileSlidesVariant1(tiles, imageGalleryId, GapBetweenTiles)
  if (MobileLayout === mobileVariants.variant2)
    return generateMobileSlidesVariant2(tiles, imageGalleryId, GapBetweenTiles)
  if (MobileLayout === mobileVariants.variant3)
    return generateMobileSlidesVariant3(tiles, imageGalleryId, GapBetweenTiles)
  return generateMobileSlidesDefault(tiles, imageGalleryId)
}

const generateMobileSlidesVariant1 = (tiles = [], imageGalleryId, GapBetweenTiles) =>
  chunkedArray(tiles, 3).map(([tile1, tile2, tile3]) => (
    <MobileImgWrapperThreeSlidesVariant
      key={getSlideKey(imageGalleryId, tile1, 'mobile')}
      GapBetweenTiles={GapBetweenTiles}
    >
      <Box sx={{ gridColumn: '1 / 3', gridRow: '1 / 2' }}>{tile1}</Box>
      <Box sx={{ gridColumn: '1 / 2', gridRow: '2 / 3' }}>{tile2}</Box>
      <Box sx={{ gridColumn: '2 / 3', gridRow: '2 / 3' }}>{tile3}</Box>
    </MobileImgWrapperThreeSlidesVariant>
  ))

// Latest design change for Mobile D&J
const generateMobileSlidesVariant2 = (tiles = [], imageGalleryId, GapBetweenTiles) =>
  variedChunkedArray(tiles, [1, 3, 1, 3, 2]).map(([tile1, tile2, tile3], idx) => {
    const key = getSlideKey(imageGalleryId, tile1, 'mobile')
    if (idx % 5 === 0) {
      return (
        <DynamicSlideMobile
          GapBetweenTiles={GapBetweenTiles}
          cols="1fr"
          rows="1fr"
          key={key}
          maxWidth="398.7px"
          width="79.7vw"
        >
          <Box sx={{ gridColumn: '1 / 2', gridRow: '1 / 2' }}>{tile1}</Box>
        </DynamicSlideMobile>
      )
    }
    if (idx % 5 === 1) {
      return (
        <DynamicSlideMobile
          GapBetweenTiles={GapBetweenTiles}
          cols={`repeat(2, calc(50% - ${GapBetweenTiles / 2}px))`}
          rows={`calc(49% - ${GapBetweenTiles / 2}px) calc(51% - ${GapBetweenTiles / 2}px)`}
          key={key}
          maxWidth="408.96px"
          width="81.8vw"
        >
          <Box sx={{ gridColumn: '1 / 3', gridRow: '1 / 2' }}>{tile1}</Box>
          <Box sx={{ gridColumn: '1 / 2', gridRow: '2 / 3' }}>{tile2}</Box>
          <Box sx={{ gridColumn: '2 / 3', gridRow: '2 / 3' }}>{tile3}</Box>
        </DynamicSlideMobile>
      )
    }
    if (idx % 5 === 2) {
      return (
        <DynamicSlideMobile
          GapBetweenTiles={GapBetweenTiles}
          cols="1fr"
          rows="1fr"
          key={key}
          maxWidth="664.1px"
          width="132.8vw"
        >
          <Box sx={{ gridColumn: '1 / 2', gridRow: '1 / 2' }}>{tile1}</Box>
        </DynamicSlideMobile>
      )
    }
    if (idx % 5 === 3) {
      return (
        <DynamicSlideMobile
          GapBetweenTiles={GapBetweenTiles}
          cols={`repeat(2, calc(50% - ${GapBetweenTiles / 2}px))`}
          rows={`calc(44% - ${GapBetweenTiles / 2}px) calc(56% - ${GapBetweenTiles / 2}px)`}
          key={key}
          maxWidth="467.9px"
          width="93.59vw"
        >
          <Box sx={{ gridColumn: '1 / 2', gridRow: '1 / 2' }}>{tile1}</Box>
          <Box sx={{ gridColumn: '2 / 3', gridRow: '1 / 2' }}>{tile2}</Box>
          <Box sx={{ gridColumn: '1 / 3', gridRow: '2 / 3' }}>{tile3}</Box>
        </DynamicSlideMobile>
      )
    }
    return (
      <DynamicSlideMobile
        GapBetweenTiles={GapBetweenTiles}
        cols="1fr"
        rows={`calc(57% - ${GapBetweenTiles / 2}px) calc(43% - ${GapBetweenTiles / 2}px)`}
        key={key}
        maxWidth="223.1px"
        width="44.62vw"
      >
        <Box sx={{ gridColumn: '1 / 2', gridRow: '1 / 2' }}>{tile1}</Box>
        <Box sx={{ gridColumn: '1 / 2', gridRow: '2 / 3' }}>{tile2}</Box>
      </DynamicSlideMobile>
    )
  })

const generateMobileSlidesVariant3 = (tiles = [], imageGalleryId, GapBetweenTiles) =>
  variedChunkedArray(tiles, [1, 3, 1, 3, 3, 2]).map(([tile1, tile2, tile3], idx) => {
    const key = getSlideKey(imageGalleryId, tile1, 'mobile')
    if (idx % 6 === 0) {
      return (
        <DynamicSlide GapBetweenTiles={GapBetweenTiles} sx={{ width: '521px' }} key={key}>
          <Box sx={{ gridColumn: '1 / 13', gridRow: '1 / 13' }}>{tile1}</Box>
        </DynamicSlide>
      )
    }
    if (idx % 6 === 1) {
      return (
        <DynamicSlide GapBetweenTiles={GapBetweenTiles} sx={{ width: '319px' }} key={key}>
          <Box sx={{ gridColumn: '1 / 7', gridRow: '1 / 6' }}>{tile1}</Box>
          <Box sx={{ gridColumn: '7 / 13', gridRow: '1 / 6' }}>{tile2}</Box>
          <Box sx={{ gridColumn: '1 / 13', gridRow: '6 / 13' }}>{tile3}</Box>
        </DynamicSlide>
      )
    }
    if (idx % 6 === 2) {
      return (
        <DynamicSlide GapBetweenTiles={GapBetweenTiles} sx={{ width: '313px' }} key={key}>
          <Box sx={{ gridColumn: '1 / 13', gridRow: '1 / 13' }}>{tile1}</Box>
        </DynamicSlide>
      )
    }
    if (idx % 6 === 3) {
      return (
        <DynamicSlide GapBetweenTiles={GapBetweenTiles} sx={{ width: '319px' }} key={key}>
          <Box sx={{ gridColumn: '1 / 6', gridRow: '1 / 7' }}>{tile1}</Box>
          <Box sx={{ gridColumn: '6 / 13', gridRow: '1 / 7' }}>{tile2}</Box>
          <Box sx={{ gridColumn: '1 / 13', gridRow: '7 / 13' }}>{tile3}</Box>
        </DynamicSlide>
      )
    }
    if (idx % 6 === 4) {
      return (
        <DynamicSlide GapBetweenTiles={GapBetweenTiles} sx={{ width: '305px' }} key={key}>
          <Box sx={{ gridColumn: '1 / 6', gridRow: '1 / 6' }}>{tile1}</Box>
          <Box sx={{ gridColumn: '6 / 13', gridRow: '1 / 6' }}>{tile2}</Box>
          <Box sx={{ gridColumn: '1 / 13', gridRow: '6 / 13' }}>{tile3}</Box>
        </DynamicSlide>
      )
    }
    return (
      <DynamicSlide GapBetweenTiles={GapBetweenTiles} sx={{ width: '174px' }} key={key}>
        <Box sx={{ gridColumn: '1 / 13', gridRow: '1 / 8' }}>{tile1}</Box>
        <Box sx={{ gridColumn: '1 / 13', gridRow: '8 / 13' }}>{tile2}</Box>
      </DynamicSlide>
    )
  })

const generateMobileSlidesDefault = (tiles = [], imageGalleryId) =>
  tiles.map?.(tile => <MobileImgWrapper key={getSlideKey(imageGalleryId, tile, 'mobile')}>{tile}</MobileImgWrapper>)

export const generateImgData = imageObj => ({
  desktop: {
    url: imageObj.url,
  },
  mobile: {
    url: imageObj.url,
  },
  alt: imageObj.alternativeText,
  height: '100%',
  width: '100%',
  imageSizing: 'cover',
})

export const chunkedArray = (arr, size) => {
  const tempArr = []
  if (!arr) return []
  for (let i = 0; i < arr.length; i += size) {
    const chunk = arr.slice(i, i + size)
    tempArr.push(chunk)
  }
  return tempArr
}

const variedChunkedArray = (arr, sizeArr) => {
  const tempArr = []
  if (!arr || !sizeArr) return []
  let indexArr = 0
  let indexSizeArr = 0

  while (indexArr < arr.length) {
    const chunk = arr.slice(indexArr, indexArr + sizeArr[indexSizeArr])
    tempArr.push(chunk)
    indexArr += sizeArr[indexSizeArr]
    indexSizeArr = (indexSizeArr + 1) % sizeArr.length
  }
  return tempArr
}

export const generateItemKey = (item, imageGalleryId) => (typeName = '') =>
  `${imageGalleryId}-${item?.__typename}-${item?.[typeName]?.id || item?.[typeName]}`.toLowerCase()

export const getSlideKey = (imageGalleryId, item, device) => `${imageGalleryId}-${item?.__typename}-${device}-slide`

export const SpaceBetweenEnum = {
  small: 7,
  medium: 10,
  large: 15,
}
