import { useCallback, useState } from 'react'

/**
 * Controls M-ui elevation using mouse-hover and mouse-leave
 * @param inactive {number} default inactive elevation value
 * @param active {number} active elevation value
 * @returns {{ onMouseEnter: () => void, onMouseLeave: () => void, elevation: number }}
 */
const useHoverElevation = (inactive = 2, active = 6) => {
  const [elevation, setElevation] = useState(inactive)

  const onMouseEnter = useCallback(() => {
    setElevation(active)
  }, [active])

  const onMouseLeave = useCallback(() => {
    setElevation(inactive)
  }, [inactive])

  return {
    onMouseEnter,
    onMouseLeave,
    elevation,
  }
}

export default useHoverElevation
